import Vue from 'vue'
import VueRouter from 'vue-router'
import Tabbar from '@/views/layout/Tabbar'
import store from '@/store'
import { Toast } from 'vant'

Vue.use(VueRouter)

// 路由懒加载
const getComponent = (name, component) => () => import(`@/views/${name}/${component}.vue`)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  //   component: getComponent('home', 'index')
  // },
  {
    path: '/',
    component: Tabbar,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'HomeIndex',
        component: getComponent('home', 'index'),
        meta: { title: '首页', keepAlive: true }
      },
      {
        path: '/customer/index',
        name: 'CustomerIndex',
        component: getComponent('customer', 'index'),
        meta: { title: '客户', keepAlive: true }
      },
      {
        path: '/contract/index',
        name: 'ContractIndex',
        component: getComponent('contract', 'index'),
        meta: { title: '合同', keepAlive: true }
      },
      {
        path: '/case/index',
        name: 'CaseIndex',
        component: getComponent('case', 'index'),
        meta: { title: '案件', keepAlive: true }
      },
      {
        path: '/user/index',
        name: 'UserIndex',
        component: getComponent('user', 'index'),
        meta: { title: '我的', keepAlive: true }
      },
      {
        path: '/home/pool',
        name: 'HomePool',
        component: getComponent('home', 'pool'),
        meta: { title: '公海列表', keepAlive: true }
      }
    ]
  },
  {
    path: '/customer/detail',
    name: 'CustomerDetail',
    component: getComponent('customer', 'detail'),
    meta: { title: '客户详情', keepAlive: false }
  },
  {
    path: '/customer/create',
    name: 'CustomerCreate',
    component: getComponent('customer', 'create'),
    meta: { title: '新建客户', keepAlive: false }
  },
  {
    path: '/contract/create',
    name: 'ContractCreate',
    component: getComponent('contract', 'create'),
    meta: { title: '新建合同', keepAlive: false }
  },
  {
    path: '/contract/detail',
    name: 'ContractDetail',
    component: getComponent('contract', 'detail'),
    meta: { title: '合同详情', keepAlive: false }
  },
  {
    path: '/receivables/create',
    name: 'ReceivablesCreate',
    component: getComponent('receivables', 'create'),
    meta: { title: '新建付款', keepAlive: false }
  },
  {
    path: '/receivables/detail',
    name: 'ReceivablesDetail',
    component: getComponent('receivables', 'detail'),
    meta: { title: '付款详情', keepAlive: false }
  },
  {
    path: '/receivables/billing',
    name: 'ReceivablesBilling',
    component: getComponent('receivables', 'billing'),
    meta: { title: '申请开票', keepAlive: false }
  },
  {
    path: '/travel/create',
    name: 'TravelCreate',
    component: getComponent('travel', 'create'),
    meta: { title: '出差申请', keepAlive: false }
  },
  {
    path: '/receivables/refund',
    name: 'ReceivablesRefundCreate',
    component: getComponent('receivablesRefund', 'create'),
    meta: { title: '退费减免申请', keepAlive: false }
  },
  {
    path: '/cost/create',
    name: 'CostCreate',
    component: getComponent('cost', 'create'),
    meta: { title: '费用报销', keepAlive: false }
  },
  {
    path: '/case/detail',
    name: 'CaseDetail',
    component: getComponent('case', 'detail'),
    meta: { title: '案件详情', keepAlive: false }
  },
  {
    path: '/contacts/create',
    name: 'ContactsCreate',
    component: getComponent('contacts', 'create'),
    meta: { title: '新建联系人', keepAlive: false }
  },
  {
    path: '/home/taskList',
    name: 'HomeTaskList',
    component: getComponent('home', 'taskList'),
    meta: { title: '待办页面', keepAlive: false }
  },
  {
    path: '/home/followUpRanking',
    name: 'HomeFollowUpRanking',
    component: getComponent('home', 'followUpRanking'),
    meta: { title: '跟进排名', keepAlive: false }
  },
  {
    path: '/user/personDetail',
    name: 'UserPersonDetail',
    component: getComponent('user', 'personDetail'),
    meta: { title: '个人信息', keepAlive: false }
  },
  {
    path: '/user/statistical',
    name: 'UserStatistical',
    component: getComponent('user', 'statistical'),
    meta: { title: '推广部总结', keepAlive: false }
  },
  {
    path: '/receivables/billDetail',
    name: 'ReceivablesBillDetail',
    component: getComponent('receivables', 'billDetail'),
    meta: { title: '开票详情', keepAlive: false }
  },
  {
    path: '/travel/detail',
    name: 'TravelDetail',
    component: getComponent('travel', 'detail'),
    meta: { title: '差旅详情', keepAlive: false }
  },
  {
    path: '/cost/detail',
    name: 'CostDetail',
    component: getComponent('cost', 'detail'),
    meta: { title: '费用报销详情', keepAlive: false }
  },
  {
    path: '/receivables/refundDetail',
    name: 'ReceivablesRefundDetail',
    component: getComponent('receivablesRefund', 'detail'),
    meta: { title: '退费减免详情', keepAlive: false }
  },
  {
    path: '/case/waitHandover',
    name: 'WaitHandover',
    component: getComponent('case', 'waitHandover'),
    meta: { title: '待交接案件', keepAlive: false }
  },
  {
    path: '/home/canCost',
    name: 'CanCost',
    component: getComponent('home', 'canCost'),
    meta: { title: '可报销案件', keepAlive: true }
  },
  {
    path: '/lawfirmContract/index',
    name: 'lawfirmContractIndex',
    component: getComponent('lawfirmContract', 'index'),
    meta: { title: '律所合同', keepAlive: true }
  },
  {
    path: '/lawfirmContract/detail',
    name: 'lawfirmContractDetail',
    component: getComponent('lawfirmContract', 'detail'),
    meta: { title: '合同详情', keepAlive: false }
  },
  {
    path: '/lawyerTools/index',
    name: 'LawyerTools',
    component: getComponent('lawyerTools', 'index'),
    meta: { title: '律师工具', keepAlive: true }
  },
  {
    path: '/followTemplate/create',
    name: 'FollowTemplateCreate',
    component: getComponent('customer', 'FollowTemplateEdit'),
    meta: { title: '新建跟进指导', keepAlive: false }
  },
  {
    path: '/cost/auditDetail',
    name: 'CostAuditDetail',
    component: getComponent('cost', 'auditDetail'),
    meta: { title: '费用报销审批详情', keepAlive: false }
  },
  {
    path: '/buckle/detail',
    name: 'BuckleDetail',
    component: getComponent('buckle', 'detail'),
    meta: { title: '补扣详情', keepAlive: false }
  },
  {
    path: '/bidding/index',
    name: 'BiddingIndex',
    component: getComponent('bidding', 'index'),
    meta: { title: '竞价统计', keepAlive: false }
  },
  {
    path: '/home/checkContract',
    name: 'CheckContract',
    component: getComponent('home', 'checkContract'),
    meta: { title: '待审合同', keepAlive: false }
  },
  {
    path: '/visit/index',
    name: 'VisitIndex',
    component: getComponent('visit', 'index'),
    meta: { title: '上门登记', keepAlive: false }
  },
  {
    path: '/visit/create',
    name: 'VisitCreate',
    component: getComponent('visit', 'create'),
    meta: { title: '添加上门登记', keepAlive: false }
  },
  {
    path: '/receivables/codepay',
    name: 'codepay',
    component: getComponent('receivables', 'codepay'),
    meta: { title: '收款', keepAlive: false }
  },
  {
    path: '/404',
    component: () => import('@/views/404')
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  // personRouter,
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log('路由定位：', to, from)
  var token = store.state.user.token
  if (!token) {
    // 记录前置跳转
    var object = {
      path: to.path,
      query: to.query
    }
    if (to.path !== '/login') {
      localStorage.setItem('redirectUrl', JSON.stringify(object))
      next('/login')
    } else {
      next()
    }
  } else {
    // 获取全局通用配置
    if (!store.state.system.crm_config) {
      await store.dispatch('system/crmGenConfig')
    }
    if (to.path == '/login') {
      Toast.fail('当前已登录')
      next('/home')
    } else {
      next()
    }
  }
})

export default router
